import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dev-panel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_ctx.isDev)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          icon: "settings",
          onClick: _ctx.togglePanel,
          variant: "plain",
          color: "text-primary"
        }, null, 8, ["onClick"]),
        _createVNode(_component_v_overlay, {
          modelValue: _ctx.isPanelOpen,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isPanelOpen) = $event)),
          class: "dev-overlay"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "dev-card" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Developer Tools")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      color: "error",
                      onClick: _ctx.resetStore
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Reset Store ")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      color: "success",
                      onClick: _ctx.createFakeAccount
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Create Test Account ")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      color: "secondary",
                      onClick: _ctx.togglePanel
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Close ")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : _createCommentVNode("", true)
}