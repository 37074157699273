import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-header mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    modelValue: _ctx.overlay,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.overlay) = $event)),
    error: _ctx.error,
    width: 300,
    onClose: _ctx.closeModal
  }, {
    header: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('modal.change' + _ctx.mode)), 1)
    ]),
    default: _withCtx(() => [
      (_ctx.mode === 'email')
        ? (_openBlock(), _createBlock(_component_v_form, {
            key: 0,
            ref: "emailForm",
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateFormValidity('emailForm'))),
            onSubmit: _withModifiers(_ctx.updateEmail, ["prevent"]),
            class: "form"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PasswordField, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                rules: [_ctx.validators.required, _ctx.validators.spacesForbidden],
                label: _ctx.$t('modal.password')
              }, null, 8, ["modelValue", "rules", "label"]),
              _createVNode(_component_TextField, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                autocomplete: "email",
                rules: [_ctx.validators.required, _ctx.validators.spacesForbidden, _ctx.validators.email],
                label: _ctx.$t('modal.newEmail')
              }, null, 8, ["modelValue", "rules", "label"]),
              _createVNode(_component_v_btn, {
                disabled: !_ctx.formValid.emailForm,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('modal.changeButton')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 8, ["onSubmit"]))
        : (_ctx.mode === 'login')
          ? (_openBlock(), _createBlock(_component_v_form, {
              key: 1,
              ref: "loginForm",
              onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateFormValidity('loginForm'))),
              onSubmit: _withModifiers(_ctx.updateLogin, ["prevent"]),
              class: "form"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PasswordField, {
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
                  rules: [_ctx.validators.required, _ctx.validators.spacesForbidden],
                  label: _ctx.$t('modal.password')
                }, null, 8, ["modelValue", "rules", "label"]),
                _createVNode(_component_TextField, {
                  modelValue: _ctx.login,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.login) = $event)),
                  autocomplete: "login",
                  rules: [_ctx.validators.required, _ctx.validators.spacesForbidden, _ctx.validators.login],
                  label: _ctx.$t('modal.login')
                }, null, 8, ["modelValue", "rules", "label"]),
                _createVNode(_component_v_btn, {
                  disabled: !_ctx.formValid.loginForm,
                  type: "submit"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('modal.changeButton')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : (_openBlock(), _createBlock(_component_v_form, {
              key: 2,
              ref: "passwordForm",
              onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateFormValidity('passwordForm'))),
              onSubmit: _withModifiers(_ctx.updatePassword, ["prevent"]),
              class: "form"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PasswordField, {
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.password) = $event)),
                  rules: [_ctx.validators.required, _ctx.validators.min8, _ctx.validators.spacesForbidden],
                  label: _ctx.$t('modal.password'),
                  hint: _ctx.$t('validators.minCharacters', { count: 8 }),
                  counter: ""
                }, null, 8, ["modelValue", "rules", "label", "hint"]),
                _createVNode(_component_PasswordField, {
                  modelValue: _ctx.newPassword,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newPassword) = $event)),
                  autocomplete: "new-password",
                  rules: [_ctx.validators.required, _ctx.validators.min8, _ctx.validators.spacesForbidden],
                  label: _ctx.$t('modal.newPassword'),
                  hint: _ctx.$t('validators.minCharacters', { count: 8 }),
                  counter: ""
                }, null, 8, ["modelValue", "rules", "label", "hint"]),
                _createVNode(_component_v_btn, {
                  disabled: !_ctx.formValid.passwordForm,
                  type: "submit"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('modal.changeButton')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            }, 8, ["onSubmit"]))
    ]),
    _: 1
  }, 8, ["modelValue", "error", "onClose"]))
}