import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, { eager: true }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps({
        color: "text-primary",
        size: "large",
        variant: "plain"
      }, props), {
        append: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "material-icons" }, " menu ", -1)
        ])),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.$t('menu.menu')), 1)
        ]),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createElementVNode("nav", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu.home')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('profileClick'))),
          to: { name: 'profile' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu.profile')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: { name: 'wiki' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu.wiki')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: { name: 'stats' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu.stats')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: { name: 'about' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu.about')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}