import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, {
    modelValue: _ctx.fieldValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldValue) = $event)),
    autocomplete: _ctx.autocomplete,
    rules: _ctx.rules,
    label: _ctx.label,
    hint: _ctx.hint,
    counter: _ctx.counter,
    type: _ctx.type,
    class: "w-100 mb-2",
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.fieldValue)))
  }, null, 8, ["modelValue", "autocomplete", "rules", "label", "hint", "counter", "type"]))
}