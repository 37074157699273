import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      class: "mr-1",
      href: "https://discord.gg/DR9cEDDNdN",
      target: "_blank",
      density: "comfortable",
      variant: "plain",
      color: "invert",
      size: "large",
      icon: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          class: "social-icon",
          size: "large",
          icon: "fa:fa-brands fa-discord"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_btn, {
      density: "comfortable",
      href: "https://github.com/Razdva122/avalon",
      target: "_blank",
      variant: "plain",
      color: "invert",
      size: "large",
      icon: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          class: "social-icon",
          size: "large",
          icon: "fa:fa-brands fa-github"
        })
      ]),
      _: 1
    })
  ]))
}