import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, {
    modelValue: _ctx.passwordValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.passwordValue) = $event)),
    autocomplete: _ctx.autocomplete,
    "append-inner-icon": _ctx.visible ? 'visibility_off' : 'visibility',
    type: _ctx.visible ? 'text' : 'password',
    rules: _ctx.rules,
    label: _ctx.label,
    hint: _ctx.hint,
    counter: _ctx.counter,
    class: "w-100 mb-2",
    "onClick:appendInner": _cache[1] || (_cache[1] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.passwordValue)))
  }, null, 8, ["modelValue", "autocomplete", "append-inner-icon", "type", "rules", "label", "hint", "counter"]))
}