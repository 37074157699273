import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-message mb-2 ml-2"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createBlock(_component_v_overlay, {
    modelValue: _ctx.overlay,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.overlay) = $event)),
    persistent: _ctx.persistent,
    class: "align-center justify-center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_sheet, {
            width: _ctx.$props.width,
            class: "mx-auto pa-4"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header", {}, undefined, true),
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createTextVNode(_toDisplayString(_ctx.$t('errors.' + _ctx.error)) + " ", 1),
                    (_ctx.additionalError)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.additionalError), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["width"]),
          _createVNode(_component_v_btn, {
            onClick: _ctx.closeModal,
            class: "close",
            icon: "close",
            color: "text-primary",
            variant: "text",
            density: "compact"
          }, null, 8, ["onClick"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["modelValue", "persistent"]))
}