import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    modelValue: _ctx.overlay,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.overlay) = $event)),
    error: _ctx.error,
    width: 300,
    persistent: true,
    onClose: _ctx.closeAuthModal
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_tabs, {
        modelValue: _ctx.mode,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mode) = $event)),
        class: "tabs mb-4",
        color: "text-primary",
        "align-tabs": "center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tab, { value: "auth" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('modal.loginTab')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_tab, { value: "registration" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('modal.registrationTab')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    default: _withCtx(() => [
      (_ctx.mode === 'auth')
        ? (_openBlock(), _createBlock(_component_v_form, {
            key: 0,
            ref: "loginForm",
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateFormValidity('loginForm'))),
            onSubmit: _withModifiers(_ctx.loginInAccount, ["prevent"]),
            class: "form"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TextField, {
                modelValue: _ctx.login,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.login) = $event)),
                autocomplete: "login",
                rules: [_ctx.validators.required, _ctx.validators.spacesForbidden],
                label: _ctx.$t('modal.email') + '/' + _ctx.$t('modal.login')
              }, null, 8, ["modelValue", "rules", "label"]),
              _createVNode(_component_PasswordField, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                rules: [_ctx.validators.required, _ctx.validators.spacesForbidden],
                label: _ctx.$t('modal.password')
              }, null, 8, ["modelValue", "rules", "label"]),
              _createVNode(_component_v_btn, {
                disabled: !_ctx.formValid.loginForm,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('modal.loginButton')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 8, ["onSubmit"]))
        : (_openBlock(), _createBlock(_component_v_form, {
            key: 1,
            ref: "registrationForm",
            onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateFormValidity('registrationForm'))),
            onSubmit: _withModifiers(_ctx.registerUser, ["prevent"]),
            class: "form"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('modal.loginHint')), 1),
              _createVNode(_component_TextField, {
                modelValue: _ctx.login,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.login) = $event)),
                autocomplete: "login",
                rules: [_ctx.validators.required, _ctx.validators.spacesForbidden, _ctx.validators.login],
                label: _ctx.$t('modal.login'),
                type: "login"
              }, null, 8, ["modelValue", "rules", "label"]),
              _createVNode(_component_TextField, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.email) = $event)),
                autocomplete: "email",
                rules: [_ctx.validators.required, _ctx.validators.email, _ctx.validators.spacesForbidden],
                label: _ctx.$t('modal.email'),
                type: "email"
              }, null, 8, ["modelValue", "rules", "label"]),
              _createVNode(_component_PasswordField, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.password) = $event)),
                rules: [_ctx.validators.required, _ctx.validators.min8, _ctx.validators.spacesForbidden],
                label: _ctx.$t('modal.password'),
                hint: _ctx.$t('validators.minCharacters', { count: 8 }),
                counter: ""
              }, null, 8, ["modelValue", "rules", "label", "hint"]),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('modal.usernameHint')), 1),
              _createVNode(_component_TextField, {
                modelValue: _ctx.username,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.username) = $event)),
                autocomplete: "nickname",
                rules: [_ctx.validators.required],
                label: _ctx.$t('modal.username')
              }, null, 8, ["modelValue", "rules", "label"]),
              _createVNode(_component_v_btn, {
                disabled: !_ctx.formValid.registrationForm,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('modal.registrationButton')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 8, ["onSubmit"]))
    ]),
    _: 1
  }, 8, ["modelValue", "error", "onClose"]))
}